import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Link,
    Tooltip,
    Typography,
} from '@mui/material'
import { startCase } from 'lodash-es'
import { useEffect, useState } from 'react'
import AceEditor from 'react-ace'
import { API_URI, HEADERS } from '../appConfigs'

const StdOutDialog = ({ open, setOpen, title, filepath }) => {
    const [loadedFilepath, setLoadedFilepath] = useState()
    const [content, setContent] = useState()
    const [loading, setLoading] = useState(true)
    const [runId, setRunId] = useState()

    const download = () => {
        const blob = new Blob([content], {
            type: 'text',
        })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `pandium_run_${runId}_stdout`
        link.click()
    }

    useEffect(() => {
        const getStdOut = async () => {
            setLoading(true)
            try {
                if (content && loadedFilepath === filepath) return
                const response = await fetch(`${API_URI}/files/${filepath}`, {
                    method: 'GET',
                    headers: HEADERS(sessionStorage.getItem('token')),
                })
                if (response.ok) {
                    const text = await response.text()
                    let formatted
                    try {
                        formatted = JSON.stringify(JSON.parse(text), null, '\t')
                    } catch (e) {
                        formatted = text
                    }
                    setContent(formatted)
                    setLoadedFilepath(filepath)
                }
            } catch (error) {
                console.error(
                    `Error fetching STDOUT for filepath ${filepath}: ${error}`
                )
            } finally {
                setLoading(false)
            }
        }

        if (open) {
            getStdOut()
            // parse the run ID from the filepath
            setRunId(filepath.split('/').pop().split('_')[1])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, filepath])

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            maxWidth={'sm'}
            fullWidth={true}
            sx={{ padding: '20px' }}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent sx={{ pb: 2 }}>
                {loading ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <CircularProgress />
                    </Box>
                ) : !content ? (
                    <Typography>Error fetching STDOUT</Typography>
                ) : (
                    <>
                        <Typography sx={{ mb: 1 }}>
                            Showing STDOUT from run{' '}
                            <Link
                                sx={{ color: '#1976d2' }}
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`/runs/${runId}/show`}
                            >
                                {runId}
                            </Link>
                            .
                        </Typography>
                        <AceEditor
                            mode="text"
                            value={content}
                            theme="cobalt"
                            width={'100%'}
                            fontSize={12}
                            lineHeight={19}
                            readOnly={true}
                        />
                    </>
                )}
            </DialogContent>
            <DialogActions
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                    pl: '24px',
                    width: 'full',
                }}
            >
                <Button
                    disabled={!content}
                    variant={'text'}
                    className={'filledButton'}
                    sx={{
                        mb: 2,
                        '&:hover': {
                            backgroundColor: '#353DDF',
                        },
                    }}
                    onClick={() => download()}
                >
                    Download
                </Button>
                <Button onClick={() => setOpen(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ({ label, runType, filepath }) => {
    const [open, setOpen] = useState(false)
    return (
        <>
            <Box
                sx={{
                    mt: '3.5px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
            >
                <Typography
                    sx={{
                        color: '#6d6d6d',
                        mr: 1,
                    }}
                >
                    {label}
                </Typography>
                <Tooltip
                    title={
                        filepath === undefined &&
                        `No STDOUT for the
                            ${runType.replaceAll('_', ' ')}`
                    }
                >
                    <span>
                        <Button
                            sx={{ display: 'flex' }}
                            disabled={filepath === undefined}
                            onClick={() => {
                                setOpen(true)
                            }}
                        >
                            Show
                        </Button>
                    </span>
                </Tooltip>
            </Box>
            <StdOutDialog
                open={open}
                setOpen={setOpen}
                title={`${startCase(runType)} STDOUT`}
                filepath={filepath}
            />
        </>
    )
}
