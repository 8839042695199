import React, { useEffect, useRef, useState } from 'react'
import {
    SimpleShowLayout,
    Datagrid,
    TextField,
    DateField,
    Link,
    useRedirect,
    ListContextProvider,
    useDataProvider,
    useGetOne,
    DeleteButton,
    useUpdate,
} from 'react-admin'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Card, Button, LinearProgress } from '@mui/material'
import Box from '@mui/material/Box'
import {
    PageTitle,
    HorizontalTextField,
    HorizontalScheduleField,
    RunPhaseField,
    useConnectors,
} from '../../common'
import {
    CheckCircle,
    Public,
    Error,
    KeyboardArrowLeft,
} from '@mui/icons-material'
import SyncButton from '../../SyncButton'
import ConnectButton from '../../Auth/SimpleConnectorButton'
import { isEmpty, get } from 'lodash-es'
import { formatDate } from '@thefront/pandipackV2/src/utils'
import { EmptyState } from '../../react_admin/EmptyState'

import { isConnectorConnected, Capitalize } from '@thefront/pandipackV2'
import BaseShow from '../Show/BaseShow'
import { useListController } from 'ra-core'
import { SUBGRID_RECORDS_PER_PAGE } from '../../../appConfigs'
import { RunFilter } from '../runs/RunList'
import ListPagination from '../List/ListPagination'
import ConnectorInfoButton from '../../ConnectorInfoButton'
import ConnectorSecretsButton from '../../ConnectorSecretsButton'
import LinkField from '../../LinkField'
import StdOutField from '../../StdOutField'
import RunBulkActionButtons from '../List/BulkEdit/RunBulkActionButtons'

const tenantShowStyles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    boldLarge: {
        fontFamily: 'RobotoCondensedBold',
        fontSize: '20px',
        paddingBottom: '5px',
    },
    regLarge: {
        color: '#6d6d6d',
        fontSize: '20px',
        marginBottom: '10px',
    },
    generalButton: {
        fontFamily: 'RobotoCondensedBold',
        color: '#3664E7',
        fontSize: '15px',
        letterSpacing: '2px',
        textAlign: 'center',
        lineHeight: '14px',
    },
    globalIcon: {
        ml: '5px',
    },
    fullWidthBox: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-around',
        boxShadow: 'none',
    },
    connectorsBox: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    connector: {
        flex: '1',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'flex-start',
    },
    connectorLogo: {
        height: '40px',
        width: '40px',
        margin: '0 5px',
        objectContain: 'cover',
    },
    connectorName: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    green: {
        color: '#3CF6C8',
        ml: '5px',
    },
    red: {
        color: '#FF4C4C',
        ml: '5px',
    },
    flex1: {
        flex: '1',
        padding: '3px',
    },
    flex2: {
        flex: '2',
    },
    configurationCard: {
        borderBottom: '1px solid #D8D8D8',
        borderRight: '1px solid #D8D8D8',
        borderTop: '1px solid #D8D8D8',
        boxShadow: 'none',
        flex: '1',
        borderRadius: '0',
        padding: '36.5px 50px 25.5px 50px',
        margin: '0px 0 30px 0',
    },
    detailsCard: {
        borderBottom: '1px solid #D8D8D8',
        borderLeft: '1px solid #D8D8D8',
        borderTop: '1px solid #D8D8D8',
        boxShadow: 'none',
        flex: '1',
        borderRadius: '0',
        margin: '0px 0 30px 0',
        padding: '36.5px 50px 25.5px 50px',
    },
    firstCard: {
        display: 'flex',
        flexDirection: 'row',
        width: '95%',
        justifyContent: 'space-around',
        boxShadow: 'none',
        flex: '1',
        borderRadius: '0',
        margin: '0px 0 30px 0',
        padding: '10px 50px 10px 50px',
    },
    titleRoot: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    buttonWrapper: {
        display: 'flex',
    },
    titleWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    connectorButtonContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
}

const TenantTopToolBar = (props) => {
    const redirect = useRedirect()
    const { tenant } = props
    const SYNCTYPES =
        get(tenant, 'integration.type') === 'Pandium'
            ? ['init', 'normal', 'force_build']
            : ['init', 'normal']

    const [update] = useUpdate('tenants', {
        id: tenant.id,
        data: { ...tenant, archived: false },
        previousData: tenant,
    })

    const handleBack = () => {
        redirect('/tenants')
    }

    const handleSettings = () => {
        redirect('/tenants/' + tenant.id + '/edit/settings')
    }

    const handleSchedule = () => {
        redirect('/tenants/' + tenant.id + '/edit/schedule')
    }

    return tenant ? (
        <Box>
            <Button onClick={handleBack} sx={tenantShowStyles.generalButton}>
                <KeyboardArrowLeft /> All Tenants{' '}
            </Button>
            <Box sx={tenantShowStyles.titleRoot}>
                <Box sx={tenantShowStyles.titleWrapper}>
                    <PageTitle title="Tenant Detail" />
                </Box>
                <Box sx={tenantShowStyles.buttonWrapper}>
                    {tenant.archived ? (
                        <Button
                            onClick={() => update()}
                            sx={tenantShowStyles.generalButton}
                        >
                            Unarchive Tenant
                        </Button>
                    ) : !tenant.integration || tenant.integration?.archived ? (
                        <DeleteButton
                            record={tenant}
                            resource="tenants"
                            path="/tenants"
                        >
                            Archive Tenant
                        </DeleteButton>
                    ) : (
                        [
                            <Button
                                key="settings"
                                sx={tenantShowStyles.generalButton}
                                onClick={handleSettings}
                            >
                                SETTINGS
                            </Button>,
                            <Button
                                key="sync_schedule"
                                sx={tenantShowStyles.generalButton}
                                onClick={handleSchedule}
                            >
                                SYNC SCHEDULE
                            </Button>,
                            <SyncButton
                                key="sync"
                                label="SYNC NOW"
                                record={tenant}
                                disabled={
                                    !get(tenant, 'status.auth.connected', false)
                                }
                                modes={SYNCTYPES}
                                buttonStyles={{
                                    fontFamily: 'RobotoCondensedBold',
                                    color: 'white',
                                    width: '165px',
                                    height: '42px',
                                    margin: '15px',
                                    backgroundColor: '#626FFC',
                                    fontSize: '15px',
                                    lineHeight: '21px',
                                    letterSpacing: '2px',
                                    borderRadius: '0',
                                    alignSelf: 'center',
                                    '&:hover:hover': {
                                        backgroundColor: '#626FFC',
                                    },
                                }}
                            />,
                        ]
                    )}
                </Box>
            </Box>
        </Box>
    ) : null
}

const getCurrentConnector = (connectors, name) => {
    return connectors.find((connector) => {
        return get(connector, 'name', '') === name
    })
}

const GeneralCard = ({ record = {}, connectors = {} }) => {
    const isSuperUser = useSelector((state) => state.org.isSuperUser) || false
    return (
        <Card sx={tenantShowStyles.firstCard}>
            <Box sx={tenantShowStyles.flex1}>
                <Box sx={tenantShowStyles.regLarge}>NAME</Box>
                <Box sx={tenantShowStyles.boldLarge}>{get(record, 'name')}</Box>
            </Box>
            <Box sx={tenantShowStyles.flex1}>
                <Box sx={tenantShowStyles.regLarge}>INTEGRATION</Box>
                {!record.integration ? (
                    'Deleted'
                ) : (
                    <Link
                        to={
                            '/integrations/' +
                            get(record, 'integration.id') +
                            '/show'
                        }
                        sx={tenantShowStyles.boldLarge}
                    >
                        {record.integration?.archived
                            ? get(record, 'integration.name') + ' (archived)'
                            : get(record, 'integration.name')}
                    </Link>
                )}
            </Box>
            {!record.archived && (
                <Box sx={tenantShowStyles.flex2}>
                    <Box sx={tenantShowStyles.regLarge}>CONNECTOR STATUS</Box>
                    <Box sx={tenantShowStyles.connectorsBox}>
                        {!isEmpty(connectors) && !isEmpty(record) ? (
                            get(record, 'integration.connectors', []).map(
                                (connector) => {
                                    const currentConnector = getCurrentConnector(
                                        connectors,
                                        connector.name
                                    )
                                    return !isEmpty(currentConnector) ? (
                                        <Box
                                            key={connector.name}
                                            sx={tenantShowStyles.connector}
                                        >
                                            <img
                                                src={currentConnector.logoUrl}
                                                alt="option logo"
                                                style={
                                                    tenantShowStyles.connectorLogo
                                                }
                                            />
                                            <Box>
                                                <Box
                                                    sx={
                                                        tenantShowStyles.connectorName
                                                    }
                                                >
                                                    <Box
                                                        component="span"
                                                        sx={{
                                                            ...tenantShowStyles.boldLarge,
                                                            paddingTop: '5px',
                                                        }}
                                                    >
                                                        {get(
                                                            currentConnector,
                                                            'label',
                                                            Capitalize(
                                                                currentConnector.name
                                                            )
                                                        )}
                                                    </Box>
                                                    {connector.isGlobal ? (
                                                        <Public
                                                            sx={
                                                                tenantShowStyles.globalIcon
                                                            }
                                                        />
                                                    ) : isConnectorConnected(
                                                          record,
                                                          connector.name
                                                      ) ? (
                                                        <CheckCircle
                                                            sx={
                                                                tenantShowStyles.green
                                                            }
                                                        />
                                                    ) : (
                                                        <Error
                                                            sx={
                                                                tenantShowStyles.red
                                                            }
                                                        />
                                                    )}
                                                </Box>
                                                <Box
                                                    sx={
                                                        tenantShowStyles.connectorButtonContainer
                                                    }
                                                >
                                                    {!connector.isGlobal && (
                                                        <ConnectButton
                                                            tenant={record}
                                                            connector={
                                                                currentConnector
                                                            }
                                                        />
                                                    )}
                                                    <ConnectorInfoButton
                                                        tenant={record}
                                                        connector={
                                                            currentConnector
                                                        }
                                                    />
                                                    {isSuperUser &&
                                                        !connector.isGlobal &&
                                                        isConnectorConnected(
                                                            record,
                                                            connector.name
                                                        ) && (
                                                            <ConnectorSecretsButton
                                                                tenant={record}
                                                                connector={
                                                                    currentConnector
                                                                }
                                                            />
                                                        )}
                                                </Box>
                                            </Box>
                                        </Box>
                                    ) : null
                                }
                            )
                        ) : (
                            <LinearProgress />
                        )}
                    </Box>
                </Box>
            )}
        </Card>
    )
}

const Configs = ({ record = {} }) =>
    record.configs ? (
        <Card sx={tenantShowStyles.configurationCard}>
            <Box component="span" sx={tenantShowStyles.regLarge}>
                CONFIGURATION
            </Box>
            {Object.keys(record.configs).map((key) => (
                <HorizontalTextField
                    key={key}
                    record={record.configs}
                    source={key}
                />
            ))}
        </Card>
    ) : null

const Details = ({ record = {}, connectors, ...props }) => {
    record.createdDate = formatDate(
        record.createdDate?.toString(),
        false,
        'M/d/yyyy, pp'
    )?.split(',')[0]

    return (
        <Card sx={tenantShowStyles.detailsCard}>
            <Box component="span" sx={tenantShowStyles.regLarge}>
                DETAILS
            </Box>
            <HorizontalTextField
                record={record}
                rowName="ID"
                source="id"
                label={false}
            />
            <HorizontalTextField
                record={record}
                rowName="CREATED DATE"
                source="createdDate"
                label={false}
            />
            <HorizontalTextField
                record={record}
                rowName="RELEASE"
                source="integrationRelease.name"
                label={false}
            />
            <HorizontalTextField
                record={record}
                rowName="RELEASE TAG"
                source="integrationRelease.tag"
                label={false}
            />
            <HorizontalTextField
                record={record}
                rowName="RELEASE CHANNEL"
                source="integrationReleaseChannel"
                label={false}
            />
            <HorizontalScheduleField
                record={record}
                source="schedule"
                rowName="SCHEDULE"
                label={false}
                tenant={record}
                {...props}
            />
            <HorizontalScheduleField
                record={record}
                source="schedule"
                rowName="LAST RUN"
                label={false}
                tenant={record}
                {...props}
            />
            <HorizontalScheduleField
                record={record}
                source="schedule"
                rowName="NEXT RUN"
                tenant={record}
                label={false}
                {...props}
            />
            {!isEmpty(connectors) && !isEmpty(record)
                ? get(record, 'integration.connectors', []).map((connector) => {
                      const currentConnector = getCurrentConnector(
                          connectors,
                          connector.name
                      )

                      const displayTag = get(
                          currentConnector,
                          'metadata.user_identifier.display'
                      )

                      const connectorAuthName = connector.name.toUpperCase()
                      const tenantStatusAuth = get(record, 'status.auth')
                      const isConnected =
                          tenantStatusAuth &&
                          get(
                              tenantStatusAuth,
                              `${connectorAuthName}_AUTH_STATUS`
                          ) === 'Connected'

                      const customerDisplayPath = `connectedUsers.${get(
                          currentConnector,
                          'metadata.tenant_label_key'
                      )}.attributes.${get(
                          currentConnector,
                          'metadata.tenant_label_key'
                      )}.${displayTag}`
                      //this is how we get the account's display keys stored in
                      // tenant.connectedUsers -> account -> attributes -> account (which get set on connections)
                      return (
                          !isEmpty(currentConnector) && (
                              <Box key={currentConnector.name}>
                                  {get(record, customerDisplayPath) && (
                                      <HorizontalTextField
                                          record={record}
                                          rowName={(
                                              get(
                                                  currentConnector,
                                                  'metadata.tenant_label_key'
                                              ) +
                                              ' ' +
                                              displayTag
                                          ).toUpperCase()}
                                          source={customerDisplayPath}
                                          label=""
                                      />
                                  )}
                                  <HorizontalTextField
                                      record={tenantStatusAuth}
                                      rowName={`${connectorAuthName} ${
                                          isConnected
                                              ? 'CONNECTION'
                                              : 'DISCONNECT'
                                      } DATE`}
                                      source={`${connectorAuthName}_LAST_CHANGE`}
                                      label={false}
                                  />
                              </Box>
                          )
                      )
                  })
                : null}
            <Box sx={{ height: '6px' }} />
            <StdOutField
                filepath={record.status?.lastRun?.stdOut}
                runType={'last_run'}
                label={'LAST RUN STDOUT'}
            />
            <StdOutField
                filepath={record.status?.lastSuccessfulRun?.stdOut}
                runType={'last_successful_run'}
                label={'LAST SUCCESSFUL RUN STDOUT'}
            />
        </Card>
    )
}

const TenantRunDataGrid = (props) => (
    <>
        <Datagrid
            empty={<EmptyState emptyStateText={'No runs yet.'} />}
            rowClick={'show'}
            {...props}
            sx={{
                '& .RaDatagrid-tableWrapper': {
                    marginTop: '20px',
                    border: '1px solid #DBDEE5',
                    boxShadow: 'none',
                },
            }}
            resource={'runs'}
            bulkActionButtons={<RunBulkActionButtons />}
        >
            <TextField source="id" label={'ID'} sortable={false} />
            <TextField
                source="jobSpec.integration.name"
                label={'INTEGRATION'}
                sortable={false}
            />
            <TextField source="mode" label={'MODE'} sortable={false} />
            <TextField source="trigger" label={'TRIGGER'} sortable={false} />
            <DateField source="startedDate" label="STARTED" showTime />
            <DateField source="completedDate" label="COMPLETED" showTime />
            <RunPhaseField source="status.phase" label="STATUS" />
            <LinkField
                // a.detailLink is defined in the theme styles in appConfigs.js, to allow us to target
                // this field for the hover effect
                className="detailLink"
                basePath="runs"
                redirect="show"
                sortable={true}
                variant="outlined"
                text="SHOW LOGS"
            />
        </Datagrid>
    </>
)

const ConfigsDetailsCard = ({
    record = {},
    connectors = {},

    ...props
}) => {
    return (
        <Box sx={tenantShowStyles.fullWidthBox}>
            <Configs record={record} />
            <Details record={record} connectors={connectors} {...props} />
        </Box>
    )
}

export const TenantShowFilter = ({ ...props }) => {
    return (
        <Box sx={tenantShowStyles.container}>
            <Box component="span" sx={tenantShowStyles.regLarge}>
                {props.label || 'RUNS'}
            </Box>
            <RunFilter {...props} showAllFilters={false} storeKey="subList" />
        </Box>
    )
}

export const TenantRunList = ({ tenant }) => {
    const controllerProps = useListController({
        perPage: SUBGRID_RECORDS_PER_PAGE,
        bulkActionButtons: false,
        sort: { field: 'createdDate', order: 'DESC' },
        filter: { tenantId: tenant.id },
        actions: null,
        basePath: '/runs',
        resource: 'runs',
        disableSyncWithLocation: true,
        storeKey: 'subList',
    })

    //after the component mounts, refetch the runs every time the tenant's lastRun changes.
    //this is dependent on the parent component polling the tenant.
    const mounted = useRef(false)
    useEffect(() => {
        if (mounted.current) {
            controllerProps.refetch()
        } else {
            mounted.current = true
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenant?.status?.lastRun, tenant?.status?.currentRun])

    return (
        <ListContextProvider value={controllerProps}>
            <TenantShowFilter />
            <TenantRunDataGrid />
            <ListPagination />
        </ListContextProvider>
    )
}

const TenantShow = ({ record }) => {
    const [tenant, setTenant] = useState(record)
    const connectors = useConnectors(tenant.integration.connectors)

    const dataProvider = useDataProvider()
    const tenantCallback = (event) => {
        setTenant(event.payload)
    }

    useEffect(() => {
        dataProvider.subscribe(`tenants/${tenant.id}`, tenantCallback)
        return () =>
            dataProvider.unsubscribe(`tenants/${tenant.id}`, tenantCallback)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <BaseShow
            resource={'tenants'}
            actions={null}
            TopToolbar={<TenantTopToolBar tenant={tenant} />}
        >
            <SimpleShowLayout>
                <GeneralCard record={tenant} connectors={connectors} />
                <ConfigsDetailsCard record={tenant} connectors={connectors} />
                <TenantRunList tenant={tenant} />
            </SimpleShowLayout>
        </BaseShow>
    )
}

export const TenantShowWrapper = () => {
    const { id } = useParams()
    const redirect = useRedirect()
    const { data, isLoading } = useGetOne(
        'tenants',
        { id },
        { onError: () => redirect('/tenants') }
    )

    return !isLoading && <TenantShow record={data} />
}
